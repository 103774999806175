// !------------- Core React and State Management -------------!
// !--- react ---!
import React from 'react';
// Documentation: https://reactjs.org/

/**
 * AnimatedText component displays animated text with customizable colors.
 * @component
 * @param {object} props - Component props
 * @param {string} props.text - The text to be displayed
 * @param {object} props.colors - Object containing main and secondary color values
 * @returns {JSX.Element} Animated text component
 */
export default function AnimatedText({ text, colors }) {
  /**
   * Formats the input text into lines with maximum 3 words per line
   * @param {string} inputText - The text to be formatted
   * @returns {string} Formatted text
   */
  const formatText = (inputText) => {
    const cleanText = inputText.split('®')[0];
    const words = cleanText.split(' ');
    let newText = '';
    for (let i = 0; i < words.length; i++) {
      newText += words[i];
      if ((i + 1) % 3 === 0 && i + 1 !== words.length) {
        newText += '\n';
      } else if (i + 1 !== words.length) {
        newText += ' ';
      }
    }
    return newText;
  };

  /**
   * Generates text style based on whether it's main or secondary
   * @param {boolean} isMain - Indicates if it's main text or not
   * @returns {object} Text style object
   */
  const textStyle = (isMain) => ({
    fill: 'none',
    strokeDasharray: '70 80',
    strokeDashoffset: '0',
    strokeLinejoin: 'round',
    strokeWidth: '1',
    stroke: isMain ? colors.main : colors.secondary,
    animation: isMain ? 'strokeAnimation 5s infinite linear -5s' : 'strokeAnimation 5s infinite linear -2.5s',
  });

  // Format the input text
  const formattedText = formatText(text);
  // Split the text into lines
  const lines = formattedText.split('\n');
  // Calculate vertical offset for centering the text
  const yOffset = -((lines.length - 1) * 1.2) / 2 + 'em';

  return (
    <>
      <style>
        {`
          @keyframes strokeAnimation {
            100% {
              stroke-dashoffset: -150;
            }
          }
        `}
      </style>
      {/* SVG for displaying the animated text */}
      <svg viewBox="0 0 600 300" style={{ position: 'absolute', width: '100%', height: '100%' }}>
        {/* Symbol for text element */}
        <symbol id="s-text">
          {/* Text element */}
          <text textAnchor="middle" x="50%" y="50%" dy={yOffset} style={{ fontSize: '1.2em', fontFamily: '"Open Sans", Helvetica, Impact', textTransform: 'uppercase', letterSpacing: '0.1em' }}>
            {/* Map through lines and create tspan elements */}
            {lines.map((line, index) => (
              <tspan key={index} x="50%" dy={index === 0 ? '0em' : '1.2em'}>
                {line}
              </tspan>
            ))}
          </text>
        </symbol>
        {/* Render multiple text elements with different styles */}
        {[...Array(5)].map((_, index) => (
          <use key={index} xlinkHref="#s-text" style={textStyle(index % 2 === 0)} />
        ))}
      </svg>
    </>
  );
}
