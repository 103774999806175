import React, { useState, useEffect, useRef, useCallback } from "react";

export default function ToggleColor({ icon, colors, isActive }) {
  const [isIconColored, setIsIconColored] = useState(false);
  const toggleRef = useRef(null);

  const handleIconClick = () => {
    setIsIconColored(prev => !prev);
  };

  const handleClickOutside = useCallback((event) => {
    if (toggleRef.current && !toggleRef.current.contains(event.target) && isIconColored) {
      setIsIconColored(false);
    }
  }, [isIconColored]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const currentColor = isActive !== undefined ? isActive : isIconColored;

  const iconStyle = {
    color: currentColor ? colors.accent : colors.secondary
  };

  return (
    <div ref={toggleRef}>
      <div style={iconStyle} onClick={handleIconClick}>
        {icon}
      </div>
    </div>
  );
}
