import React, { useState, useEffect } from 'react';
import NavBar from './NavBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Grid, Divider } from "@mui/material";
import { tidy, summarize, sum } from '@tidyjs/tidy';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // Ensure you import Chart from chart.js/auto to register controllers, elements, scales, and plugins.

const DashboardComponent = ({ data, colors, Params }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [totalValue, setTotalValue] = useState(0); // State variable to hold the total value
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isEmbedded, setIsEmbedded] = useState(window.self !== window.top);
  const [mostFrequentAssociationName, setMostFrequentAssociationName] = useState('');
  const [participationRate, setParticipationRate] = useState(0); 
  const [investorCount, setInvestorCount] = useState(0);

  const getYearField = (selectedYear) => {
    const currentYear = new Date().getFullYear();
    const yearDiff = currentYear - selectedYear;
    if (Params.association.type === 'RPAC') {
      switch (yearDiff) {
        case 0:
          return 'RPAC_CURRENT_YEAR';
        case 1:
          return 'RPAC_YEAR_MINUS_1';
        case 2:
          return 'RPAC_YEAR_MINUS_2';
        default:
          return 'RPAC_CURRENT_YEAR';
      }
  } else if ( Params.association.type === 'CFA') {
    switch (yearDiff) {
      case 0:
        return 2024;
      case 1:
        return 2023;
      case 2:
        return 2022;
      default:
        return 2024;
    }
  }
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {

    if (data && data.length > 0) {
      const yearField = getYearField(selectedYear);
      if (Params.association.type === 'RPAC') {
      } else if (Params.association.type === 'CFA') {
        const filteredData = data.filter(item => item.transactionyear === yearField);
      }
    }
  }, [data, selectedYear]);

  let partValue = parseFloat(Params.association.paricipation);

  useEffect(() => {
    if (data && data.length > 0) {
      const yearField = getYearField(selectedYear);
      if (Params.association.type === 'RPAC') {
        const total = tidy(data, summarize({ value: sum(yearField) }));
        setTotalValue(total[0]['value']); 
        const majorInvestors = data.filter(d => d[yearField] >  parseInt(Params.association.majorDonorAmount, 10) ).length;
        setMajorInvestorCount(majorInvestors);
        const participants = data.filter(d => d[yearField] >= parseInt(Params.association.investorAmount, 10)).length;
        setInvestorCount(participants);
        const rate = (participants / partValue) * 100;
        setParticipationRate(rate.toFixed(2)); 
      } else if (Params.association.type === 'CFA') {
        const filteredData = data.filter(item => item.transactionyear === yearField);
        const sumTransactionsMade = filteredData.reduce((acc, item) => acc + item.transactions_made, 0);
        setTotalValue(sumTransactionsMade);
        const majorInvestors = filteredData.filter(item => item.transactions_made > 5).length;
        setMajorInvestorCount(majorInvestors);
        const participants = filteredData.length;
        setInvestorCount(participants);
        const rate = (participants / data.length) * 100;
        setParticipationRate(rate.toFixed(2)); 
      }
    }
  }, [data, selectedYear, Params]);
  
  useEffect(() => {
    if (data && data.length > 0) {
      const nameCounts = data.reduce((acc, cur) => {
        const name = cur.ASSOCIATION_NAME;
        acc[name] = (acc[name] || 0) + 1;
        return acc;
      }, {});
  
      const mostFrequentName = Object.keys(nameCounts).reduce((a, b) => nameCounts[a] > nameCounts[b] ? a : b);
      setMostFrequentAssociationName(mostFrequentName);
    }
  }, [data]);


  const MiniCard = ({ title, value }) => {
    return (
      <Card style={{ flex: 1, minWidth: 0, display: 'flex', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
        <CardContent style={{ flexGrow: 1, display: 'flex' }}>
          <Grid container spacing={1} style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography
                gutterBottom
                variant="overline"
                align="center"
                style={{ wordWrap: 'break-word' }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item style={{ height: '70%', display: 'flex', alignItems: 'center' }}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography color="textPrimary" align="center" variant="h5" style={{ wordWrap: 'break-word' }}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };



  const [majorInvestorCount, setMajorInvestorCount] = useState(0);
  const [raisedPercentage, setRaisedPercentage] = useState(0); // Initial raised percentage
 let numberValue = parseFloat(Params.association.goal);




  useEffect(() => {
    if (Params.association.goal > 0) { // Prevent division by zero
      const newRaisedPercentage = (totalValue / numberValue) * 100;
      setRaisedPercentage(newRaisedPercentage.toFixed(2)); // Keep two decimal places
    }
  }, [totalValue, numberValue]);
  
let label1 = 'RPAC Investments';
let label2 = 'Participants';
let datasetLabel = 'Dollars';

if (Params.association.type === 'RPAC') {
  // No changes required
} else if (Params.association.type === 'CFA') {
  label1 = 'Total Actions';
  label2 = 'Remaining Goal';
  datasetLabel = 'Actions';
}

const chartData = {
  labels: [label1], // Assuming you want to label the entire stack with "Total Raised"
  datasets: [
    {
      label: 'Total Raised',
      data: [totalValue], // This will be on bottom
      backgroundColor: colors.main,
      borderColor: colors.main,
    },
    {
      label: 'Goal Remaining',
      data: [Math.max(0, numberValue - totalValue)], // This will be on top
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
    }
  ],
};

const chartOptions = {
  scales: {
    x: { // For Chart.js 3.x and later, use 'x' instead of 'xAxes'
      stacked: true,
      ticks: {
        color: 'black', // Change color here
        font: {
          size: 25 // Set font size here
        }
      }
    },
    y: { // Use 'y' instead of 'yAxes'
      stacked: true,
    },
  },
  plugins: {
    legend: {
      display: false, // Set to true if you want to display the legend
    },
  },
};


const chartDataPar = {
  labels: [label2], // Assuming you want to label the entire stack with "Total Raised"
  datasets: [
    {
      label: 'Total Number of Participants',
      data: [investorCount], // This will be on bottom
      backgroundColor: colors.secondary,
      borderColor: colors.secondary,
    },
    {
      label: 'Goal Remaining',
      data: [Math.max(0, partValue - investorCount)], // This will be on top
      backgroundColor: colors.main,
      borderColor: colors.main,
    }
  ],
};
const chartOptionsPar = {
  scales: {
    x: { // For Chart.js 3.x and later, use 'x' instead of 'xAxes'
      stacked: true,
      ticks: {
        color: 'black', // Change color here
        font: {
          size: 25 // Set font size here
        }
      }
    },
    y: { // Use 'y' instead of 'yAxes'
      stacked: true,
    },
  },
  plugins: {
    legend: {
      display: false, // Set to true if you want to display the legend
    },
  },
};


  let layoutStyle = Params.association.layout === 'long' ? { display: 'flex', flexDirection: 'column' } : {};
  
  let title;
let formattedValue;
let raisedTitle;
let goalTitle;
let investorsTitle;
let majorInvestorsTitle;
let formattedGoal;
if (Params.association.type === 'RPAC') {
  title = "Total Dollars Raised";
  formattedValue = totalValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
   formattedGoal = numberValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // Set minimumFractionDigits to 0 to remove decimals
    maximumFractionDigits: 0, 
  })
  raisedTitle = "Raised";
  goalTitle = "Goal";
  investorsTitle = "Investors";
  majorInvestorsTitle = "Major Investors";
} else if (Params.association.type === 'CFA') {
  title = "Total Actions";
  formattedValue = totalValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  formattedGoal = numberValue.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  raisedTitle = "Actions %";
  goalTitle = "Goal";
  investorsTitle = "Advocates";
  majorInvestorsTitle = "Major Advocates";
}




  return (
    <div className={`dashboard ${isFullScreen ? 'full-screen' : ''} ${isEmbedded ? 'embedded' : ''}`}>
    { Params.association.layout === 'Full' ? (
      <>
        <NavBar onYearChange={handleYearChange} colors={colors} Params={Params} data={data} />
        <h1 style={{ marginTop: "60px" }}>{Params.association.associationName}</h1>
      </>
    ) : null}
    <div className="mini-cards-container" style={layoutStyle}>
    <MiniCard
  title={title}
  value={formattedValue}
/>
<MiniCard title={raisedTitle} value={`${raisedPercentage}%`} />
<MiniCard title={goalTitle} value={formattedGoal} />
<MiniCard title="Participation Rate" value={`${participationRate}%`} />
<MiniCard title={investorsTitle} value={investorCount.toLocaleString()} />
<MiniCard title={majorInvestorsTitle} value={majorInvestorCount.toLocaleString()} />
      </div>
      {Params.association.layout === 'Full' ? (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'space-around', // This centers the charts and provides space between them
      alignItems: 'center', // This vertically aligns the charts (if they have different heights)
      width: '90%', 
      margin: '40px auto', 
      padding: '10px', 
      backgroundColor: '#fff', // Adjust the background color as needed
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)' 
    }}>
      {/* Chart 1 Container */}
      <div style={{ width: '45%', height: '450px' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      
      {/* Chart 2 Container */}
      <div style={{ width: '45%', height: '450px' }}>
        <Bar data={chartDataPar} options={chartOptionsPar} />
      </div>
    </div>
    ) : null}
      {/* <button className="toggle-fullscreen" onClick={toggleFullScreen}>
        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
      </button> */}
    </div>
  );
};

export default DashboardComponent;
