import React, { useState, useEffect } from 'react';
import './App.css';
import { fetchMemberAdvocacyData, fetchMemberData, filterMemberData } from './Utils/utilities.js';
import { WrangleMemberData } from './Utils/Wrangle.js'
import { CleanedData } from './Utils/VariablesCleaningSpecification.js';
import OverallTheme from './Styles/OverallTheme.js';
import BuildIcon from '@mui/icons-material/Build';
import AnimatedText from './components/Animated Logo/AnimatedLogo.js';
import { Tooltip, IconButton, FormControlLabel, FormGroup, InputLabel, FormControl, Select, MenuItem, Input, TextField, Popover, Button, Checkbox, Grid } from '@mui/material';
import DashboardComponent from './components/DashboardComponent.js';
import FeedbackForm from './components/Feedback/Feedback.js';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { SketchPicker } from 'react-color';
import Papa from 'papaparse';
import validateKey from './Utils/ValidateKey.js';
import submitSettings from './Utils/submitSettings.js';

const initialStructure = {
  dashboard: {
    version: 1.00,
    name: 'RPAC Snapshot',
  },
  association: {
    historical: true,
    company: 'REAL',
    paricipation: 100,
    dollarstype: 'both',
    investorAmount: 15,
    majorDonorAmount: 1000,
    badgesEnable: true,
    presidentsListEnable: true,
    badge1Name: '',
    badge1Amount: 0,
    badge2Name: '',
    badge2Amount: 0,
    badge3Name: '',
    badge3Amount: 0,
    type: "RPAC",
    layout: 'Full',
  }
};

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isTestingMode, setIsTestingMode] = useState(false);
  const [initialParams, setInitialParams] = useState(initialStructure);
  const [editableParams, setEditableParams] = useState(initialStructure);

  function updateParamsBasedOnQuery(queryParams, currentParams) {
    let updatedParams = { ...currentParams };
    if (queryParams.has('id')) {
      updatedParams.association.id = queryParams.get('id');
    }
    if (queryParams.has('type')) {
      updatedParams.association.type = queryParams.get('type');
    }
    if (queryParams.has('layout')) {
      updatedParams.association.layout = queryParams.get('layout');
    }
    if (queryParams.has('admin')) {
      updatedParams.dashboard.demo = queryParams.get('admin') === 'true';
    }
    if (queryParams.has('internal')) {
      updatedParams.dashboard.internal = queryParams.get('internal') === 'true';
    }
    return updatedParams;
  }
  async function readDataFromCSV(csvContent) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvContent, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          const mappedData = results.data.map(item => ({
            ID: item.MEMBER_ID,
            SOURCE_STATE_ASSOCIATION_ID: item.SOURCE_STATE_ASSOCIATION_ID,
            MEMBER_ASSOCIATION_ID: item.MEMBER_ASSOCIATION_ID,
            RPAC_YEAR_MINUS_2_HARD: item.RPAC_YEAR_MINUS_2_HARD,
            RPAC_YEAR_MINUS_1_HARD: item.RPAC_YEAR_MINUS_1_HARD,
            RPAC_CURRENT_YEAR_HARD: item.RPAC_CURRENT_YEAR_HARD,
            RPAC_YEAR_MINUS_2_SOFT: item.RPAC_YEAR_MINUS_2_SOFT,
            RPAC_YEAR_MINUS_1_SOFT: item.RPAC_YEAR_MINUS_1_SOFT,
            RPAC_CURRENT_YEAR_SOFT: item.RPAC_CURRENT_YEAR_SOFT,
            MEMBER_ASSOCIATION_NAME: item.MEMBER_ASSOCIATION_NAME,
          }));
          resolve(mappedData);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          reject(error);
        }
      });
    });
  }

  const fetchData = async (params) => {
    try {
      let fetchedData;
      let testingdata;
      const { id, type } = params.association;
      if (type === 'RPAC' && id === '6982') {
        fetchedData = await readDataFromCSV('./data/6985_member_data.csv');
      } else if (type === 'RPAC' && id !== '6982') {
        fetchedData = await fetchMemberData(id);
        testingdata = await filterMemberData(id);
      } else if (type === 'CFA') {
        fetchedData = await fetchMemberAdvocacyData(id);
      }
      if (fetchedData) {
        const wrangledData = WrangleMemberData(fetchedData, CleanedData());
        if (wrangledData.length > 0) {
          setData(wrangledData);
          const nameCounts = wrangledData.reduce((acc, cur) => {
            const name = cur.ASSOCIATION_NAME;
            acc[name] = (acc[name] || 0) + 1;
            return acc;
          }, {});
          const mostFrequentName = Object.keys(nameCounts).reduce((a, b) => nameCounts[a] > nameCounts[b] ? a : b);
          setMostFrequentAssociationName(mostFrequentName)
          setDisplayText(mostFrequentName);
        } else {
          console.log("No data available for the provided association ID.");
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setTimeout(() => setIsLoading(false), 5000);
    }
  };
  const [mostFrequentAssociationName, setMostFrequentAssociationName] = useState('');
  async function initializeApp() {
    let paramsToUpdate = { ...initialParams };
    try {
      const validationData = await validateKey();
      if (validationData && validationData.settings) {
        paramsToUpdate.association.goal = validationData.settings.goalAmount;
        paramsToUpdate.association.paricipation = validationData.settings.paricipation;
        paramsToUpdate.association.dollarstype = validationData.settings.dollarstype;
        paramsToUpdate.association.investorAmount = validationData.settings.investorAmount;
        paramsToUpdate.association.majorDonorAmount = validationData.settings.majorDonorAmount;
        paramsToUpdate.association.badgesEnable = validationData.settings.badgesEnable;
        paramsToUpdate.association.presidentsListEnable = validationData.settings.presidentsListEnable;
        paramsToUpdate.association.badge1Name = validationData.settings.badge1Name;
        paramsToUpdate.association.badge1Amount = validationData.settings.badge1Amount;
        paramsToUpdate.association.badge2Name = validationData.settings.badge2Name;
        paramsToUpdate.association.badge2Amount = validationData.settings.badge2Amount;
        paramsToUpdate.association.badge3Name = validationData.settings.badge3Name;
        paramsToUpdate.association.badge3Amount = validationData.settings.badge3Amount;
        paramsToUpdate.association.company = validationData.settings.company;
        paramsToUpdate.association.colortheme = validationData.settings.customColors;
        paramsToUpdate.association.presidentListIds = validationData.settings.presidentListIds;
        paramsToUpdate.association.donationLink = validationData.settings.donationLink;
      }
      let association_type = validationData.settings.assoctype;
      let associationName = validationData.settings.assocname;
      let dollarsType = validationData.settings.dollarstype;
      let fetchedData = await readDataFromCSV(validationData.csvContents);
      let filteredData;
      paramsToUpdate.association.associationName = validationData.settings.assocname;
      paramsToUpdate.association.assoctype = validationData.settings.assoctype;
      const queryParams = new URLSearchParams(window.location.search);
      paramsToUpdate = updateParamsBasedOnQuery(queryParams, paramsToUpdate);
      setInitialParams(paramsToUpdate);
      if (association_type === 'Local') {
        filteredData = fetchedData.filter(item => String(item.MEMBER_ASSOCIATION_ID) === String(initialParams.association.id));
      } else if (association_type === 'State') {
        filteredData = fetchedData.filter(item => String(item.SOURCE_STATE_ASSOCIATION_ID) === String(initialParams.association.id));
      }
      filteredData = filteredData.map(item => {
        const newItem = { ...item };
        const handleRPACYears = (softKey, hardKey, targetKey) => {
          switch (dollarsType) {
            case 'both':
              newItem[targetKey] = (parseFloat(item[softKey]) || 0) + (parseFloat(item[hardKey]) || 0);
              break;
            case 'soft':
              newItem[targetKey] = parseFloat(item[softKey]) || 0;
              break;
            case 'hard':
              newItem[targetKey] = parseFloat(item[hardKey]) || 0;
              break;
            default:
              console.error('Invalid dollarsType:', dollarsType);
          }
        };

        // Apply the function to current and previous years
        handleRPACYears('RPAC_CURRENT_YEAR_SOFT', 'RPAC_CURRENT_YEAR_HARD', 'RPAC_CURRENT_YEAR');
        handleRPACYears('RPAC_YEAR_MINUS_2_SOFT', 'RPAC_YEAR_MINUS_2_HARD', 'RPAC_YEAR_MINUS_2');
        handleRPACYears('RPAC_YEAR_MINUS_1_SOFT', 'RPAC_YEAR_MINUS_1_HARD', 'RPAC_YEAR_MINUS_1');

        return newItem;
      });
      setMostFrequentAssociationName(associationName)

      setDisplayText(associationName);
      setData(filteredData);
    } catch (error) {
      console.error('Initialization error:', error);
      return;
    }
    setTimeout(() => setIsLoading(false), 5000);
  }

  useEffect(() => {
    initializeApp(); // Removed .then() as initializeApp is now fully async
  }, []);
  const [Params, setParams] = useState(initialParams);


  const [displayText, setDisplayText] = useState(Params.association.type);

  useEffect(() => {

    if (mostFrequentAssociationName) {
      setDisplayText(mostFrequentAssociationName);
    }
  }, [mostFrequentAssociationName, Params.association.type]);



  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);



  const [stagedChanges, setStagedChanges] = useState(editableParams);



  const openFeedbackModal = () => setIsFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsFeedbackModalOpen(false);


  const [customColors, setCustomColors] = useState({});

  useEffect(() => {
    const initialColorTheme = isTestingMode ? editableParams.association.colortheme : Params.association.colortheme;

    setCustomColors({
      main: initialColorTheme?.main || '#fff',
      secondary: initialColorTheme?.secondary || '#000',
      accent: initialColorTheme?.accent || '#ccc',
    });
  }, [isTestingMode, editableParams, Params]);

  const company = isTestingMode ? editableParams.association.company : Params.association.company;

  // Define a helper to retrieve colors based on the company name
  const getThemeColors = (companyName) => {
    // Check if the mode is 'Custom' and colortheme is defined
    if (companyName === 'Custom') {
      const customTheme = isTestingMode ? stagedChanges.association.colortheme : Params.association.colortheme;

      // Check if customTheme is defined and has the required properties
      if (customTheme && customTheme.main && customTheme.secondary && customTheme.accent) {
        return customTheme;
      } else {
        // Fallback to default colors if customTheme is undefined or incomplete
        return {
          main: '#fff', // Default main color
          secondary: '#000', // Default secondary color
          accent: '#ccc', // Default accent color
        };
      }
    } else if (OverallTheme.palette[companyName]) {
      // Ensure company name exists in the palette before accessing
      return {
        main: Object.values(OverallTheme.palette[companyName].primary)[0],
        secondary: Object.values(OverallTheme.palette[companyName].primary)[1],
        accent: Object.values(OverallTheme.palette[companyName].secondary)[0],
      };
    } else {
      // Fallback colors if companyName is unexpected or not in the palette
      return {
        main: '#fff',
        secondary: '#000',
        accent: '#ccc',
      };
    }
  };


  const colors = getThemeColors(company);

  // Inline styles for the feedback button
  const feedbackButtonStyle = {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: colors.secondary,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease',
  };

  const options = ['REAL', 'Realtor', 'Advocacy', 'Commercial', 'Custom'];
  const [visiblePicker, setVisiblePicker] = useState(null);

  // Function to toggle color picker visibility
  const togglePicker = (colorKey) => {
    setVisiblePicker(visiblePicker === colorKey ? null : colorKey);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPicker, setCurrentPicker] = useState('');

  const handleClick = (event, colorKey) => {
    setAnchorEl(event.currentTarget);
    setCurrentPicker(colorKey);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentPicker('');
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function handleStagedChange(key, value, context = 'association') {
    setStagedChanges(prev => ({
      ...prev,
      [context]: {
        ...prev[context],
        [key]: value,
      },
    }));
  }
  const [newPresidentListUploaded, setNewPresidentListUploaded] = useState(false);

  const [uploadedPresidentListIds, setUploadedPresidentListIds] = useState(null);
  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          const memberIds = results.data.map(row => row['Member Ids']).filter(Boolean);
          setUploadedPresidentListIds(memberIds); // Store the uploaded IDs
          setNewPresidentListUploaded(true); // Indicate a new list has been uploaded
        },
        header: true
      });
    }
  };


  const previewChanges = () => {
    // Temporarily apply uploaded IDs for preview without altering the original params
    const newStagedChanges = {
      ...stagedChanges,
      association: {
        ...stagedChanges.association,
        // Apply uploaded president list IDs if available
        presidentListIds: uploadedPresidentListIds ? uploadedPresidentListIds : stagedChanges.association.presidentListIds,
        // Update color theme from customColors if 'Custom' is selected
        colortheme: stagedChanges.association.company === 'Custom' ? customColors : stagedChanges.association.colortheme,
      }
    };

    setStagedChanges(newStagedChanges);
  };


  const handleSubmit = async () => {
    try {
      const isCustomCompany = stagedChanges.association.company === 'Custom';
      setEditableParams(stagedChanges)
      const { association } = stagedChanges;
      let transformedParams = {
        assoctype: association.assoctype,
        assocname: association.associationName,
        goalAmount: parseInt(association.goal, 10),
        paricipation: association.paricipation,
        dollarstype: association.dollarstype,
        investorAmount: association.investorAmount,
        majorDonorAmount: association.majorDonorAmount,
        badgesEnable: association.badgesEnable,
        presidentsListEnable: association.presidentsListEnable,
        badge1Name: association.badge1Name,
        badge1Amount: association.badge1Amount,
        badge2Name: association.badge2Name,
        badge2Amount: association.badge2Amount,
        badge3Name: association.badge3Name,
        badge3Amount: association.badge3Amount,
        company: association.company,
        customColors: isCustomCompany ? customColors : undefined,
        presidentListIds: association.presidentListIds,
        donationLink: association.donationLink

      };
      if (newPresidentListUploaded) {
        transformedParams = {
          ...transformedParams,
          presidentListIds: uploadedPresidentListIds,
        };
      }
      await submitSettings(transformedParams);
      alert('Settings updated successfully!');
      window.location.reload();
    } catch (error) {
      console.error('Error submitting settings:', error);
      alert('Failed to update settings.');
    }
  };

  const onChangeComplete = (colorKey, color) => {
    // Update customColors for immediate UI feedback
    const newCustomColors = { ...customColors, [currentPicker]: color.hex };
    setCustomColors(newCustomColors);

    // Update stagedChanges with the new color, if the company is "Custom"
    if (stagedChanges.association.company === 'Custom') {
      const updatedColorTheme = {
        ...stagedChanges.association.colortheme,
        [colorKey]: color.hex
      };

      handleStagedChange('colortheme', updatedColorTheme, 'association');
    }

    handleClose();
  };

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    // Show the popup when the component loads
    setShowPopup(true);

    // Set a timer to hide the popup after 10 seconds
    const timer = setTimeout(() => {
      setShowPopup(false);
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup the timer if the component unmounts before the timer finishes
    return () => clearTimeout(timer);
  }, []); // Empty dependency array means this effect runs once on mount

  const popupStyle = {
    position: 'fixed',
    bottom: '20px',
    left: '10%',
    transform: 'translateX(-50%)',
    backgroundColor: '#ccc',
    color: '#000',
    padding: '10px',
    borderRadius: '5px',
    display: showPopup ? 'block' : 'none', // Only display the popup if showPopup is true
  };
  return (
    <>
      {isLoading ? (
        <AnimatedText text={displayText} colors={colors} />

      ) : (

        <div className="App">
          <div style={popupStyle}>
            Data is updated after 24-48 hours.
          </div>
          {
            Params.dashboard.internal && (
              <>
                <Tooltip title="Submit feedback to dashboard developer(s)">
                  <button style={feedbackButtonStyle} onClick={openFeedbackModal}>
                    <RateReviewIcon style={{ marginRight: '5px' }} /> Feedback
                  </button>
                </Tooltip>

                <FeedbackForm
                  isOpen={isFeedbackModalOpen}
                  closeModal={closeFeedbackModal}
                  dashboardName={Params.dashboard.name}
                  colors={colors}
                  Params = {Params}
                />

              </>
            )
          }




          {Params.dashboard.demo && (
            <div style={{ position: 'fixed', top: '70px', right: '20px', backgroundColor: '#fff', padding: isTestingMode ? '10px' : '0px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', transition: 'padding 0.3s', maxWidth: '400px' }}>
              <Tooltip title="Admin, click to expand and hide">
                <IconButton onClick={() => setIsTestingMode(!isTestingMode)}>
                  <BuildIcon />
                </IconButton>
              </Tooltip>

              {isTestingMode && (
                <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FormControl style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <InputLabel id="company-select-label">Style</InputLabel>
                    <Select
                      labelId="company-select-label"
                      value={stagedChanges.association.company}
                      onChange={(e) => handleStagedChange('company', e.target.value, 'association')}
                      input={<Input />}
                    >
                      {options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {isTestingMode && stagedChanges.association.company === 'Custom' && (
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', marginBottom: '16px' }}>
                      {['main', 'secondary', 'accent'].map((colorKey) => (
                        <div key={colorKey} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                          <label style={{ marginRight: '5px' }}>
                            {`${colorKey[0].toUpperCase()}${colorKey.slice(1)}:`}
                          </label>
                          <div
                            style={{
                              width: '36px',
                              height: '36px',
                              borderRadius: '4px',
                              background: customColors[colorKey],
                              cursor: 'pointer',
                              border: '1px solid #ddd',
                            }}
                            aria-describedby={id}
                            onClick={(e) => handleClick(e, colorKey)}
                          ></div>
                        </div>
                      ))}
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <SketchPicker
                          color={customColors[currentPicker]}
                          onChangeComplete={(color) => onChangeComplete(currentPicker, color)}
                        />
                      </Popover>
                    </div>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: '100%', marginBottom: '16px' }}>
                        <Tooltip title="Objective or target for the association." placement="top">
                          <TextField
                            label="Goal"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={stagedChanges.association.goal}
                            onChange={(e) => handleStagedChange('goal', e.target.value)}
                            InputLabelProps={{ style: { cursor: 'help' } }}
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: '100%', marginBottom: '16px' }}>
                        <Tooltip title="NAR Participation Member Count." placement="top">
                          <TextField
                            label="Participation Count"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={stagedChanges.association.paricipation}
                            onChange={(e) => handleStagedChange('paricipation', e.target.value)}
                            InputLabelProps={{ style: { cursor: 'help' } }}
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: '100%', marginBottom: '16px' }}>
                        <Tooltip title="Amount to be considered investor." placement="top">
                          <TextField
                            label="Investor Amount"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={stagedChanges.association.investorAmount}
                            onChange={(e) => handleStagedChange('investorAmount', e.target.value)}
                            InputLabelProps={{ style: { cursor: 'help' } }}
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl style={{ width: '100%', marginBottom: '16px' }}>
                        <Tooltip title="Amount to be considered major investor." placement="top">
                          <TextField
                            label="Major Investor Amount"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={stagedChanges.association.majorDonorAmount}
                            onChange={(e) => handleStagedChange('majorDonorAmount', e.target.value)}
                            InputLabelProps={{ style: { cursor: 'help' } }}
                          />
                        </Tooltip>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControl style={{ marginLeft: 0, marginTop: 14, marginBottom: '16px' }}>
                    <Tooltip title="Link for RPAC investments" placement="top">
                      <TextField
                        label="Link"
                        variant="outlined"
                        size="medium"
                        value={stagedChanges.association.donationLink}
                        onChange={(e) => handleStagedChange('donationLink', e.target.value)}
                        InputLabelProps={{ style: { cursor: 'help' } }}
                      />
                    </Tooltip>
                  </FormControl>
                  <FormControl style={{ marginBottom: '16px' }}>
              <Tooltip title="Choose the type of contributions: hard, soft, or both." placement="top">
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                  variant="outlined"
                  labelId="type-select-label"
                  value={stagedChanges.association.dollarstype}
                  onChange={(e) => handleStagedChange('dollarstype', e.target.value)}
                  input={<Input />}
                >
                  {['hard', 'soft', 'both'].map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </FormControl> 
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stagedChanges.association.badgesEnable}
                        onChange={(e) => handleStagedChange('badgesEnable', e.target.checked)}
                      />
                    }
                    label="Badges Enable"
                  />

                  {stagedChanges.association.badgesEnable && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '16px' }}>
                      {['1', '2', '3'].map((badge) => (
                        <div key={badge} style={{ display: 'flex', marginBottom: '10px' }}>
                          <TextField
                            label={`Badge ${badge} Name`}
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '10px' }}
                            value={stagedChanges.association[`badge${badge}Name`]}
                            onChange={(e) => handleStagedChange(`badge${badge}Name`, e.target.value)}
                          />
                          <TextField
                            label={`Badge ${badge} Amount`}
                            variant="outlined"
                            size="small"
                            value={stagedChanges.association[`badge${badge}Amount`]}
                            onChange={(e) => handleStagedChange(`badge${badge}Amount`, e.target.value)}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={stagedChanges.association.presidentsListEnable}
                        onChange={(e) => handleStagedChange('presidentsListEnable', e.target.checked)}
                      />
                    }
                    label="Presidents List"
                  />

                  {stagedChanges.association.presidentsListEnable && (
                    <Button variant="contained" component="label">
                      Upload CSV
                      <input
                        type="file"
                        hidden
                        onChange={handleCSVUpload}
                      />
                    </Button>
                  )}

                  <div style={{ marginTop: '16px' }}> {/* Adjust the gap as needed */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </FormGroup>
              )}
            </div>
          )}

          <DashboardComponent data={data} colors={colors} Params={isTestingMode ? editableParams : Params} />

        </div>


      )}</>
  );
}


export default App;
