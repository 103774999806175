import React, { useState } from 'react';
import {
  AppBar, Toolbar, Grid, IconButton, Menu, MenuItem, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogContentText, TextField, DialogActions, Button, Typography
} from '@mui/material';
import { Person as PersonIcon, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import ToggleColor from '../Utils/ToggleColor';
import MemberBadges from './Special/Badges';
import DonationProgressBar from './Special/DonationProgressBar';

const NavBar = ({ colors, Params, data, onYearChange }) => {
  const [showMemberLookupModal, setShowMemberLookupModal] = useState(false);
  const [memberIDInput, setMemberIDInput] = useState('');
  const [foundMember, setFoundMember] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear - 1, currentYear - 2];

  const handleYearChangeMenu = (year) => {
    onYearChange(year);
    setAnchorEl(null);
  };

  const handleMemberLookup = () => {
    const memberInfo = data.find(member => member.ID === parseInt(memberIDInput, 10));
    setFoundMember(memberInfo);
    setShowMemberLookupModal(false);
  };

  return (
    <>
      <AppBar style={{ backgroundColor: colors?.main || 'white', position: 'absolute' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid container justifyContent="right">
            <Tooltip title="Member Info" placement="bottom">
              <IconButton onClick={() => setShowMemberLookupModal(true)} sx={{ marginTop: 0.5 }}>
                <ToggleColor
                  icon={<PersonIcon style={{ fontSize: 30 }} />}
                  colors={colors}
                  isActive={showMemberLookupModal}
                />
              </IconButton>
            </Tooltip>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
              <ToggleColor
                icon={<CalendarTodayIcon style={{ fontSize: 30 }} />}
                colors={colors}
                isActive={open}
              />
            </IconButton>
            <Menu
              id="year-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => setAnchorEl(null)}
            >
              {years.map((year, index) => (
                <MenuItem key={year} onClick={() => handleYearChangeMenu(year)}>
                  {year}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Toolbar>
      </AppBar>
      <Dialog open={showMemberLookupModal} onClose={() => setShowMemberLookupModal(false)}>
        <DialogContent>
          <DialogContentText style={{ padding: '20px' }}>Enter your 9-digit Member ID:</DialogContentText>
          
          <TextField
            autoFocus
            margin="dense"
            id="member-id"
            label="Member ID"
            type="text"
            fullWidth
            variant="outlined"
            value={memberIDInput}
            onChange={(e) => setMemberIDInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowMemberLookupModal(false)}
           style={{
            color: colors.secondary,
        }}>Cancel</Button>
          <Button onClick={handleMemberLookup}
          style={{
            color: colors.main,
        }}
          
          >Lookup</Button>
        </DialogActions>
      </Dialog>
      {foundMember && (
  <Dialog open={Boolean(foundMember)} onClose={() => setFoundMember(null)}>
    <DialogTitle style={{ borderBottom: '2px solid #ccc', color: '#333', fontFamily: 'Arial, sans-serif', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span>Member Information</span>
      <MemberBadges 
        memberId={foundMember.ID} 
        donationTotal={foundMember.RPAC_CURRENT_YEAR} 
        colors={colors}
        params={Params.association}
      />
    </DialogTitle>
    <DialogContent style={{ backgroundColor: '#fff', padding: '20px' }}>
      <DialogContentText style={{ marginBottom: '15px', fontSize: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
        Hello, here's a look at your RPAC contributions:
        <Typography variant="body2" color="textSecondary">
      Please note member dues are not included in these figures
      </Typography>
      </DialogContentText>
     
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '5px' }}>
          <span style={{ fontWeight: 'bold'}}>{currentYear} Total:</span> ${foundMember.RPAC_CURRENT_YEAR}
        </div>
        <div style={{ marginBottom: '5px' }}>
          <span style={{ fontWeight: 'bold' }}>{currentYear - 1} Total:</span> ${foundMember.RPAC_YEAR_MINUS_1}
        </div>
        <div style={{ marginBottom: '5px' }}>
          <span style={{ fontWeight: 'bold' }}>{currentYear - 2} Total:</span> ${foundMember.RPAC_YEAR_MINUS_2}
        </div>
        <DonationProgressBar currentDonation={foundMember.RPAC_CURRENT_YEAR}  color = {colors.secondary} params={Params.association}/>
      </div>
    </DialogContent>
    <DialogActions style={{ borderTop: '2px solid #ccc', padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
  {/* New Donation Button */}
  {Params.association.donationLink && (
    <Button 
      href={Params.association.donationLink}
      target="_blank"
      rel="noopener noreferrer"
      variant="contained" 
      style={{
        borderRadius: 20,
        backgroundColor: colors.secondary, // Use the accent color
        padding: "6px 20px",
        fontSize: "16px",
        marginRight: 'auto', // Pushes any following elements to the opposite side
      }}>
      Invest
    </Button>
  )}

  {/* Existing Close Button */}
  <Button 
    onClick={() => setFoundMember(null)} 
    variant="contained" 
    style={{
      borderRadius: 20,
      backgroundColor: colors.main, // Main theme color
      padding: "6px 20px",
      fontSize: "16px",
      marginLeft: 'auto', // Not strictly necessary here but for consistency
    }}>
    Close
  </Button>
</DialogActions>

  </Dialog>
)}

    </>
  );
};

export default NavBar;
