// !------------- Core React and State Management -------------!
// !--- react ---!
import React, { useState, useEffect } from 'react';
// Documentation: https://reactjs.org/

// !------------- Feedback Storage, ID -------------!
// !--- airtable ---!
import Airtable from 'airtable';
// Documentation: https://github.com/airtable/airtable.js

// !--- uuid ---!
import { v4 as uuidv4 } from 'uuid';
// Documentation: https://github.com/uuidjs/uuid#readme

// !------------- Modal Features -------------!
// !--- react-modal ---!
import Modal from 'react-modal';
// Documentation: https://reactcommunity.org/react-modal/

// !------------- Custom Components and Styles -------------!
import './FeedbackForm.css';

/**
 * Component for rendering a feedback form inside a modal.
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open or not
 * @param {Function} props.closeModal - Function to close the modal
 * @param {string} props.dashboardName - Name of the dashboard for which feedback is being submitted
 * @param {Object} props.colors - Object containing colors for custom styling
 * @returns {JSX.Element} JSX element representing the FeedbackForm component
 */
export default function FeedbackForm({ isOpen, closeModal, dashboardName, colors, Params }) {
    // State for form fields
    const [formState, setFormState] = useState({
        name: Params.association.id,
        category: 'New Feature Request',
        feedback: ''
    });

    // Airtable API key
    const airtableKey = process.env.REACT_APP_AIRTABLE;
    // Initialize Airtable storage with API key and base ID
    const storage = new Airtable({ apiKey: airtableKey }).base('appMWugz0s9Whv6yC');

    // Effect to update custom color variable in CSS
    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--main-color', colors.main);
    }, [colors.main]);

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Generate unique feedback ID
        const feedbackId = uuidv4();
        // Submit feedback to Airtable
        storage('tblwCdmz2FPHmWX7k').create([{
            fields: {
                "Name": formState.name,
                "Category": formState.category,
                "Feedback": formState.feedback,
                "Dashboard": dashboardName,
                "Feedback ID": feedbackId,
            }
        }], function (err, records) {
            if (err) {
                console.error(err);
                return;
            }
            console.log('Feedback submitted to Airtable:', records);
        });
        // Reset form fields and close modal
        setFormState({ name: '', category: 'New Feature Request', feedback: '' });
        closeModal();
    };

    // Function to handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={closeModal} contentLabel="Response Form" className="response-modal">
            <form onSubmit={handleSubmit} className="response-form">
                <label>
                    Name:
                    <input type="text" name="name" value={formState.name} onChange={handleChange} required />
                </label>
                <label>
                    Category:
                    <select name="category" value={formState.category} onChange={handleChange}>
                        <option value="New Feature Request">New Feature Request</option>
                        <option value="Fix/Bug">Fix/Bug</option>
                        <option value="Remove">Remove</option>
                    </select>
                </label>
                <label>
                    Feedback:
                    <textarea name="feedback" value={formState.feedback} onChange={handleChange} required />
                </label>
                <button type="submit" className="response-button">
                    Submit
                </button>
            </form>
        </Modal>
    );
}
