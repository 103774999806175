// !------------- Material UI Components and Icons -------------! 
// !--- MUI ---! 
import { createTheme } from '@mui/material/styles';
// Documentation: https://mui.com/material-ui/customization/theming/

/**
 * OverallTheme is a customized MUI theme that defines color palettes.
 * It provides consistent color schemes across the application.
 */
const OverallTheme = createTheme({
  palette: {
    // Palette for Realtor 
    Realtor: {
      primary: {
        realtorBlue: "#006BB7",
        lightBlue: "#BED7EA", 
        cyan: "#2DA8E0",
        cobalt: "#004282",
        midnight: "#0E2036"
      },
      secondary: {
        canary: "#F5E566",
        apricot: "#FF7F61",
        code: "#6EC49B",
        teal:"#007A8A"     
      },
    },
    // Palette for Commercial 
    Commercial: {
      primary: {
        commercialRed: "#CF1F28",
        mahogany:"#52121E",
        apricot:"##FF7F61",
        cardinal:"#8F050A"
      },
      secondary: {
        canary: "#F5E566",
        apricot: "#FF7F61",
        code: "#6EC49B",
        teal:"#007A8A"     
      },
    },
    // Palette for Advocacy 
    Advocacy: {
      primary: {
        advocacyPurple:"#41134A",
        lilac:"#B03995",
        apricot:"##FF7F61",
        lavender:"#BB3DD4"
      },
      secondary: {
        canary: "#F5E566",
        apricot: "#FF7F61",
        code: "#6EC49B",
        teal:"#007A8A"     
      },
    },
    // Palette for REAL 
    REAL: {
      primary: {
        teal: '#23AB95',
        darkTeal: '#094A4F',
        black: '#000',
        white: '#FFF'
      },
      secondary: {
        lightGrey: '#D1D2D4',
        darkGrey: '#939597',
        gold: '#FDCF0B',
        darkGold: '#d19b15',
        mediumTeal: '#237077',
        darkTeal: '#094A4F',
        orange: '#F26921',
        burntOrange: '#B34D14',
      },
    }
  },
});

export default OverallTheme;
