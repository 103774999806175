export default function submitSettings(settings) {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const key = urlParams.get('key');
    const adminkey = urlParams.get('adminkey');
    const apiUrl = `https://szbpwqzz46.execute-api.us-east-1.amazonaws.com/staging/validate`;

    // Adjusted to include the settings directly
    const body = JSON.stringify({
      id,
      key,
      type: 'submit',
      adminkey,
      settings // Directly using the settings object provided
    });

    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(data => {
      if (data.message === 'Settings updated successfully') {
        console.log('Settings submitted successfully');
        resolve(data);
      } else {
        console.error('Settings submission failed:', data.message);
        reject(new Error(data.message));
      }
    })
    .catch(error => {
      console.error('Error:', error.message);
      reject(error);
    });
  });
}
