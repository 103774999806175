import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function DonationProgressBar({ currentDonation, params, color }) {
  const donationAmount = Number(currentDonation);
  const minamount = parseInt(params.investorAmount, 10);

  // Extract badge values from params and convert to numbers
  const badgeValues = {
    Gold: parseInt(params.badge1Amount, 10),
    Silver: parseInt(params.badge2Amount, 10),
    Bronze: parseInt(params.badge3Amount, 10),
  };
  // Create an array of badge values, remove NaN values if badges are not defined
  const sortedBadgeValues = Object.values(badgeValues).filter(value => !isNaN(value)).sort((a, b) => a - b);
  
  let nextGoal;
  if (donationAmount === 0) {
    nextGoal = minamount; // Initial goal if no donations
  } else if (sortedBadgeValues.length === 0 || donationAmount >= sortedBadgeValues[sortedBadgeValues.length - 1]) {
    // If no badges are defined or donation exceeds the highest badge value, use increments of $100
    nextGoal = donationAmount % 100 === 0 ? donationAmount + 100 : Math.ceil(donationAmount / 100) * 100;
  } else {
    // Find the smallest badge value greater than the current donation amount
    const nextBadgeGoal = sortedBadgeValues.find(value => value > donationAmount);
    nextGoal = nextBadgeGoal || Math.ceil(donationAmount / 100) * 100; // Use units of $100 as a fallback
  }

  const progress = (donationAmount / nextGoal) * 100;
  const remaining = nextGoal - donationAmount;

  return (
    <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
      <Box width="80%" mr={1}>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          sx={{
            height: 8, // Adjust height as needed
            backgroundColor: color ? `${color}44` : '#eee', // Lighten color for background
            '& .MuiLinearProgress-bar': {
              backgroundColor: color || '#1976d2', // Default to MUI primary if color not provided
            },
          }}
        />
      </Box>
      <Box minWidth={35} mt={2}>
        <Typography variant="body2">
          {`Only $${remaining} more to reach our next goal of $${nextGoal}. Please consider contributing today.`}
        </Typography>
      </Box>
    </Box>
  );
}

export default DonationProgressBar;
