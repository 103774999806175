// utilities/validateKey.js
export default function validateKey() {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    const key = urlParams.get('key');
    // Ensure this URL is intended for validation only
    const apiUrl = `https://szbpwqzz46.execute-api.us-east-1.amazonaws.com/staging/validate`;

    const body = JSON.stringify({ id, key, type: 'validate' });
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Key validated successfully', data.settings ? ', settings fetched' : '');
      if (data.csvContents) {
        // console.log('CSV contents converted to JSON:', data.csvContents);
      }
      resolve(data); // Resolve with the whole response data for flexibility
    })
    .catch(error => {
      console.error('Key validation failed or network error:', error.message);
      reject(error); // Reject with the caught error object
    });
  });
}
