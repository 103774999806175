import React from 'react';
import { Tooltip } from '@mui/material';
import { LocalPolice as PresidentCircleIcon, RequestQuote as MajorDonorIcon, WorkspacePremium as DonorTierIcon } from '@mui/icons-material';

function MemberBadges({ memberId, donationTotal, colors, params }) {
  // Override specific badge colors with passed colors while keeping gold/silver/bronze colors
  const badgeColors = {
    accent: colors.accent, // Passed color for President Circle
    secondary: colors.secondary, // Passed color for Major Donor
    gold: "#FFD700", // Kept color for Gold
    silver: "#C0C0C0", // Kept color for Silver
    bronze: "#CD7F32" // Kept color for Bronze
  };
  const badgesParams = {
    PresidentCircle: {
      enabled: params.presidentsListEnable, // Enable for demonstration
      color: badgeColors.accent,
      ids: params.presidentListIds ? params.presidentListIds.map(id => parseInt(id, 10)).filter(Number.isFinite) : [],
      tooltip: "President Circle"
    },
    MajorDonor: {
      enabled: params.badgesEnable,
      threshold: parseInt(params.majorDonorAmount, 10),
      color: badgeColors.secondary,
      tooltip: "Major Donor"
    }, 
    DonorTier: {
      enabled: params.badgesEnable, 
      tiers: {
        Gold: parseInt(params.badge1Amount, 10),
        Silver: parseInt(params.badge2Amount, 10),
        Bronze: parseInt(params.badge3Amount, 10),
      },
      colors: {
        Gold: badgeColors.gold,
        Silver: badgeColors.silver,
        Bronze: badgeColors.bronze
      },
      tooltips: {
        Gold: params.badge1Name,
        Silver: params.badge2Name,
        Bronze: params.badge3Name,
      }
    }
  };

  const getBadges = () => {
    let badges = [];
    
    // President Circle Badge
    if (badgesParams.PresidentCircle.enabled && badgesParams.PresidentCircle.ids.includes(memberId)) {
      badges.push(
        <Tooltip title={badgesParams.PresidentCircle.tooltip} key="PresidentCircle">
          <PresidentCircleIcon style={{ color: badgesParams.PresidentCircle.color }} />
        </Tooltip>
      );
    }

    // Major Donor Badge
    if (donationTotal >= badgesParams.MajorDonor.threshold && badgesParams.DonorTier.enabled )  {
      badges.push(
        <Tooltip title={badgesParams.MajorDonor.tooltip} key="MajorDonor">
          <MajorDonorIcon style={{ color: badgesParams.MajorDonor.color }} />
        </Tooltip>
      );
    }

    // Donor Tier Badges
    const tiers = Object.entries(badgesParams.DonorTier.tiers).sort((a, b) => b[1] - a[1]);
    for (let [tier, amount] of tiers) {
      if (donationTotal >= amount && badgesParams.DonorTier.enabled) {
        badges.push(
          <Tooltip title={badgesParams.DonorTier.tooltips[tier]} key={tier}>
            <DonorTierIcon style={{ color: badgesParams.DonorTier.colors[tier] }} />
          </Tooltip>
        );
        break; // Only show the highest tier met
      }
    }

    return badges;
  };

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      {getBadges()}
    </div>
  );
}

export default MemberBadges;
